// src/components/ArticlesAndSkills.js
import React from 'react';

const ArticlesAndSkills = () => {
    return (
        <section className="py-10 bg-white">
            <div className="container mx-auto px-4">
                <div className="mb-10">
                    <h2 className="text-3xl font-bold mb-4">Some of the Articles and Interviews include:</h2>
                    <ul className="list-disc list-inside">
                        <li><a href="http://www.bluekaizen.org/mobile-device-forensics-at-a-glance/" className="text-blue-600 hover:underline">Mobile Device Forensics</a></li>
                        <li><a href="https://www.linkedin.com/pulse/cyber-security-busy-ever-vijay-velu-l-pt-ecsa-c-eh-c-hfi" className="text-blue-600 hover:underline">Cyber Security As Busy As Ever</a></li>
                        <li><a href="https://www.linkedin.com/pulse/ooh00-down-data-sickness-vijay-velu-l-pt-ecsa-c-eh-c-hfi" className="text-blue-600 hover:underline">Data Sickness</a></li>
                        <li><a href="https://www.linkedin.com/pulse/did-i-hear-economics-cyber-security-vijay-velu-l-pt-ecsa-c-eh-c-hfi" className="text-blue-600 hover:underline">Cyber Economics</a></li>
                        <li><a href="https://www.linkedin.com/pulse/my-first-book-mobile-application-penetration-vijay" className="text-blue-600 hover:underline">My First Book</a></li>
                        <li><a href="https://www.linkedin.com/pulse/security-analyticss-which-one-damn-vijay-velu-l-pt-ecsa-c-eh-c-hfi" className="text-blue-600 hover:underline">Confusion on Security Analytics</a></li>
                        <li><a href="https://www.linkedin.com/pulse/cyber-security-predictive-data-analytics-just-vijay" className="text-blue-600 hover:underline">Predictive Data Analytics</a></li>
                        <li><a href="https://www.linkedin.com/pulse/passion-learning-vijay-velu-l-pt-ecsa-c-eh-c-hfi" className="text-blue-600 hover:underline">Career Advice</a></li>
                    </ul>
                </div>
                <div>
                    <h2 className="text-3xl font-bold mb-4">Key Skills</h2>
                    <ul className="list-disc list-inside">
                        <li>To Talk Cyber Problems</li>
                        <li>Data and Process Governance</li>
                        <li>Project Management Office</li>
                        <li>Security Architecture</li>
                        <li>Create Red and Blue Teams</li>
                        <li>Threat Intelligence and Security Operations</li>
                        <li>Business Enablement</li>
                        <li>Risk Management</li>
                        <li>Identity and Access Management</li>
                        <li>Security Budget and Business Writing</li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default ArticlesAndSkills;
