import React, { useState, useEffect } from 'react';

// The array of strings to display in typing effect
const typingStrings = [
  "a Cyber Security Tester by Passion and Digital Forensics Investigator by Choice",
  "an Innovator",
  "an Investor",
  "a Cyber Security Fellow"
];

// Typing effect component
const TypingEffect = ({ strings, typeSpeed = 150, backSpeed = 100, loop = true }) => {
  const [currentText, setCurrentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); // Keep track of current string index

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Get the full text for the current string in the array
      const fullText = strings[currentIndex];

      // Logic to add or remove characters
      if (isDeleting) {
        setCurrentText(fullText.substring(0, currentText.length - 1)); // Remove character
      } else {
        setCurrentText(fullText.substring(0, currentText.length + 1)); // Add character
      }

      // If full string is typed, start deleting after a pause
      if (!isDeleting && currentText === fullText) {
        setTimeout(() => setIsDeleting(true), 1000); // Wait before deleting
      } 
      // If string is fully deleted, move to the next string
      else if (isDeleting && currentText === "") {
        setIsDeleting(false);
        setCurrentIndex((currentIndex + 1) % strings.length); // Cycle to the next string
      }
    }, isDeleting ? backSpeed : typeSpeed); // Adjust speed based on whether typing or deleting

    return () => clearTimeout(timeout);
  }, [currentText, isDeleting, currentIndex, strings, typeSpeed, backSpeed]);

  return <span>{currentText}</span>;
};

const Header = () => {
  return (
    <header id="header" className="home bg-gray-800 text-white py-20">
      <div className="container mx-auto">
        <div className="header-content text-center">
          <h1 className="text-4xl font-bold">
            I'm{" "}
            <span className="typed">
              <TypingEffect strings={typingStrings} typeSpeed={100} backSpeed={70} />
            </span>
          </h1>
          <p className="text-xl mt-4">Just Another Cyber Security Fellow</p>
          <ul className="list-unstyled list-social flex justify-center mt-4">
            <li className="mx-2">
              <a href="https://twitter.com/vijayvkvelu">
                <i className="ion-social-twitter text-2xl"></i>
              </a>
            </li>
            <li className="mx-2">
              <a href="https://www.instagram.com/vijayvkvelu/">
                <i className="ion-social-instagram text-2xl"></i>
              </a>
            </li>
            <li className="mx-2">
              <a href="https://www.linkedin.com/in/vijayvelu/">
                <i className="ion-social-linkedin text-2xl"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
