import React, { useState } from 'react';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/contact', formData);

      if (response.status === 200) {
        setFormStatus('Your message has been sent. Thank you!');
      } else {
        setFormStatus('Failed to send message. Please try again.');
      }
    } catch (error) {
      console.error('There was an error sending the message:', error);
      setFormStatus('Failed to send message. Please try again.');
    }

    // Clear the form after submission
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  return (
    <section id="contact" className="bg-gray-100 py-20">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-10">DROP ME A NOTE</h2>
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/2 p-4">
            <ul className="text-lg mb-10">
              <li className="mb-4">
                <span>Email: <a href="mailto:hello@vijayvelu.com" className="text-blue-600 hover:underline">hello@vijayvelu.com</a></span>
              </li>
              <li>
                <span>Tel: +447970025440</span>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/2 p-4">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <input
                    type="text"
                    name="name"
                    className="w-full p-3 border rounded"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <input
                    type="email"
                    name="email"
                    className="w-full p-3 border rounded"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="subject"
                  className="w-full p-3 border rounded"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <textarea
                  name="message"
                  rows="6"
                  className="w-full p-3 border rounded"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-6 py-3 rounded hover:bg-blue-700"
                >
                  Send Message
                </button>
              </div>
              {formStatus && (
                <div className={`mt-4 ${formStatus.includes('Failed') ? 'text-red-600' : 'text-green-600'}`}>
                  {formStatus}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
