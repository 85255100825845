import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto text-center">
        <div className="socials-media mb-6">
          <ul className="list-none flex justify-center space-x-6">
            <li>
              <a href="https://twitter.com/vijayvkvelu" target="_blank" rel="noopener noreferrer">
                <i className="ion-social-twitter text-2xl hover:text-blue-400"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/vijayvkvelu/" target="_blank" rel="noopener noreferrer">
                <i className="ion-social-instagram text-2xl hover:text-pink-400"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/vijayvelu/" target="_blank" rel="noopener noreferrer">
                <i className="ion-social-linkedin text-2xl hover:text-blue-600"></i>
              </a>
            </li>
          </ul>
        </div>
        <p>&copy; {new Date().getFullYear()} All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
