import React from 'react';
import MCDS from '../assets/images/MCDS.jpg';
import BlogPost1 from '../assets/images/blog-post-1.jpg';
import BlogPost2 from '../assets/images/blog-post-2.jpg';
import BlogPost3 from '../assets/images/blog-post-3.jpg';
import MobileApp from '../assets/images/Mobileapp.jpg';
import PolishEdition from '../assets/images/Polish_edition.jpg';
import Chinese3E from '../assets/images/3E-Chinese.jpg';
import Chinese2E from '../assets/images/Chinese.jpg';

const Books = () => {
  const booksAndVideos = [
    {
      type: 'book',
      title: 'Mastering Cyber Defense Symphony',
      description: 'The Ultimate Security Orchestra: Pfsense, Wazuh, Shuffle, TheHive, and MISP for indomitable Cyber Defense',
      imageUrl: MCDS,
      link: 'https://www.amazon.com/dp/B0CBBPZWVN',
    },
    {
      type: 'book',
      title: 'Mastering Kali Linux for Advanced Penetration Testing: Fourth Edition',
      description: 'Become a cybersecurity ethical hacking expert using Metasploit, Nmap, Wireshark, and BurpSuite and enhance your pentesting skills',
      imageUrl: BlogPost1,
      link: 'https://www.amazon.com/Mastering-Linux-Advanced-Penetration-Testing-ebook/dp/B09NW3C9Y6?ref_=ast_sto_dp',
    },
    {
      type: 'book',
      title: 'Mastering Kali Linux for Advanced Penetration Testing: Third Edition',
      description: 'Secure your network with Kali Linux 2019.1 – the ultimate white hat hackers\' toolkit.',
      imageUrl: BlogPost2,
      link: 'https://www.amazon.com/Mastering-Linux-Advanced-Penetration-Testing-ebook/dp/B07GVNJR6V?ref_=ast_sto_dp',
    },
    {
      type: 'book',
      title: 'Mastering Kali Linux for Advanced Penetration Testing: Second Edition',
      description: 'Secure your network with Kali Linux - the ultimate white hat hackers\' toolkit',
      imageUrl: BlogPost3,
      link: 'https://www.amazon.com/Mastering-Linux-Advanced-Penetration-Testing-ebook/dp/B06ZXT1DPG?ref_=ast_sto_dp',
    },
    {
      type: 'book',
      title: 'Mobile Application Penetration Testing',
      description: 'Explore real-world threat scenarios, attacks on mobile applications, and ways to counter them',
      imageUrl: MobileApp,
      link: 'https://www.amazon.com/Mobile-Application-Penetration-Testing-Vijay-ebook/dp/B019IOX4Y2?ref_=ast_sto_dp',
    },
    {
      type: 'book',
      title: 'Mastering Kali Linux for Advanced Penetration Testing: Second Edition - Polish Version',
      description: 'Kali Linux. Testy penetracyjne i bezpieczenstwo sieci dla zaawansowanych. Wydanie 2',
      imageUrl: PolishEdition,
      link: 'https://www.amazon.com/Linux-penetracyjne-bezpieczenstwo-zaawansowanych-Wydanie/dp/8328340372?ref_=ast_sto_dp',
    },
    {
      type: 'book',
      title: 'Mastering Kali Linux for Advanced Penetration Testing: Third Edition - Mandarin Version',
      description: 'Kali Linux advanced penetration test (original book 3) (Chinese Edition)',
      imageUrl: Chinese3E,
      link: 'https://www.amazon.com/Linux-advanced-penetration-original-Chinese/dp/7111659473/',
    },
    {
      type: 'book',
      title: 'Mastering Kali Linux for Advanced Penetration Testing: Second Edition - Mandarin Version',
      description: 'Kali Linux Advanced Penetration Testing (2nd Edition of the original book)',
      imageUrl: Chinese2E,
      link: 'http://product.china-pub.com/7642253',
    },
    {
      type: 'youtube',
      title: 'National Cyber Defense and Research Centre - Security Standards Summit 2015',
      description: 'Talking about Mobile Ransomware/extortion in 2015',
      videoUrl: 'https://www.youtube.com/watch?v=uaKd8m6FWuc&t=222s&ab_channel=ncsssncdrc',
    },
    {
      type: 'youtube',
      title: 'Penetration Testing with Vijay Kumar Velu on his latest book',
      description: 'Packt Publishing Expert Insights.',
      videoUrl: 'https://www.youtube.com/watch?v=KbT6TUa5LZc&t=1105s&ab_channel=Packt',
    },
    {
      type: 'youtube',
      title: 'Mastering Kali Linux for Advanced Penetration Testing – Fourth Edition',
      description: 'Interview with Packt on my Fourth Book.',
      videoUrl: 'https://www.youtube.com/watch?v=BwKr0duITUA&t=771s&ab_channel=Packt',
    },
  ];

  const getYouTubeThumbnail = (videoUrl) => {
    const videoId = videoUrl.split('v=')[1]?.split('&')[0];
    return videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : '';
  };

  return (
    <section id="journal" className="bg-white py-20">
      <div className="container mx-auto">
        <div className="section-title text-center">
          <h2 className="text-3xl font-bold">Books and Videos</h2>
        </div>
        <div className="journal-block mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {booksAndVideos.map((item, index) => (
              <div key={index} className="journal-info">
                <a href={item.link || item.videoUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    src={item.type === 'youtube' ? getYouTubeThumbnail(item.videoUrl) : item.imageUrl}
                    className="rounded-lg shadow-lg w-full h-64 object-cover"
                    alt={item.title}
                  />
                </a>
                <div className="journal-txt mt-4">
                  <h4 className="text-xl font-semibold">
                    <a
                      href={item.link || item.videoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {item.title}
                    </a>
                  </h4>
                  <p className="mt-2">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Books;
