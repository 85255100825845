import React from 'react';
import ArticlesAndSkills from './ArticlesAndSkills';
import meImage from '../assets/images/me.jpg'; // Import the image

const About = () => {
  return (
    <section id="about" className="bg-gray-100 py-20">
      <div className="container mx-auto flex flex-wrap">
        <div className="w-full md:w-1/3 p-4">
          <img src={meImage} className="rounded-full shadow-lg" alt="Vijay Kumar Velu" />
        </div>
        <div className="w-full md:w-2/3 p-4">
          <h2 className="text-3xl font-bold">About Me</h2>
          <p className="text-lg mt-4">
            Vijay Kumar Velu is a seasoned Cyberpreneur, accomplished Author, dynamic Speaker, strategic Investor, and avid Blogger. With nearly two decades of IT industry experience, he has become a recognized authority in cybersecurity. Vijay is a Licensed Penetration Tester, specializing in developing technical solutions for complex cyber challenges. He has authored several influential books on cybersecurity and is actively engaged in the community through speaking engagements, Capture The Flag events, and his role as a chair member of the National Cyber Defence and Research Center (NCDRC) in India.
          </p>
          
          {/* Including Articles and Key Skills Section */}
          <ArticlesAndSkills />
        </div>
      </div>
    </section>
  );
};

export default About;
