import React, { useState } from 'react';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white shadow-lg fixed w-full z-10 top-0">
      <div className="container mx-auto flex items-center justify-between p-4">
        <div className="flex items-center">
          <Link to="header" smooth={true} className="text-2xl font-bold text-gray-800 cursor-pointer">
            Vijay Kumar Velu
          </Link>
        </div>
        <div className="block lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-gray-800 focus:outline-none"
          >
            <i className="ion-navicon-round text-3xl"></i>
          </button>
        </div>
        <div
          className={`${
            isOpen ? 'block' : 'hidden'
          } w-full lg:flex lg:items-center lg:w-auto`}
        >
          <ul className="text-gray-800 lg:flex lg:justify-between text-lg pt-4 lg:pt-0">
            <li className="lg:mx-4 my-2 lg:my-0">
              <Link
                to="header"
                smooth={true}
                className="cursor-pointer hover:text-blue-600"
              >
                Home
              </Link>
            </li>
            <li className="lg:mx-4 my-2 lg:my-0">
              <Link
                to="about"
                smooth={true}
                className="cursor-pointer hover:text-blue-600"
              >
                About
              </Link>
            </li>
            <li className="lg:mx-4 my-2 lg:my-0">
              <Link
                to="journal"
                smooth={true}
                className="cursor-pointer hover:text-blue-600"
              >
                Books & Videos
              </Link>
            </li>
            <li className="lg:mx-4 my-2 lg:my-0">
              <Link
                to="contact"
                smooth={true}
                className="cursor-pointer hover:text-blue-600"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
